<template>
    <el-dialog
            :title="title"
            width="52%"
            top="2vh"
            :visible.sync="visible">
        <div>
            <div class="f24 fwb tac pb-2" style="color: #000">{{ detail.name }}</div>
            <div class="uf uf-ac uf-pc mb-4 mt-3 op-08">
<!--                <i class="iconfont icon-company-fill mr-2" style="font-size: 18px"></i>-->
                <div class="f16">{{detail.updateTime }}</div>
            </div>
            <my-editor-view :content="detail.obj"></my-editor-view>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import MyEditorView from '@/components/my-editor-view.vue'
export default {
    components: {
        MyEditorView
    },
    data () {
        return {
            visible: false,
            detail: '',
            title: ''
        }
    },
    methods: {
        init (item) {
            this.title = item.newsName ? '新闻资讯详情' : '通知公告详情'
            this.visible = true
            item.name = item.newsName || item.noticeName
            item.obj = item.newsObj || item.noticeObj
            this.detail = item
        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 10px 50px !important;
  .ql-editor {
    overflow: inherit !important;
  }
}
</style>
